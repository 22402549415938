import React from "react"
import Img from "gatsby-image"
import { Carousel, Button } from "react-bootstrap"

const HomeCarousel = ({ data }) => {
  console.log(data)
  return (
    <Carousel>
      <Carousel.Item>
        <Img
          fluid={data.balade.childImageSharp.fluid}
          style={{
            height: "50vh",
          }}
        />
        <Carousel.Caption>
          <h3>Balade en mer </h3>
          <p>Admirez la côte atlantique depuis l'ocean.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <Img
          fluid={data.peche.childImageSharp.fluid}
          style={{
            height: "50vh",
          }}
        />
        <Carousel.Caption>
          <h3>Pêche en mer</h3>
          <p>Découvez ou perfectionnez vous à la pêche en mer avec des moniteurs passionnés !</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <Img
          fluid={data.coucherDeSoleil.childImageSharp.fluid}
          style={{
            height: "50vh",
          }}
        />
        <Carousel.Caption>
          <h3>Balade au coucher du soleil</h3>
          <p>Vivez un instant inoubliable !</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <Img
          fluid={data.bord.childImageSharp.fluid}
          style={{
            height: "50vh",
          }}
        />
        <Carousel.Caption>
          <h3>Pêche en bord de mer</h3>
          <p>
            Pêchez en surfcasting avec un champion reconnu internationalement !
          </p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <Img
          fluid={data.dophin1.childImageSharp.fluid}
          style={{
            height: "50vh",
          }}
        />
        <Carousel.Caption>
          <h3>Sortie en mer</h3>
          <p>Passez un moment magique en observant les cétacés du Gouf de Capbreton</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  )
}

export default HomeCarousel
