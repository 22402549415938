import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Img from "gatsby-image"
import SEO from "../components/seo"

import { Container, Row, Col, Button } from "react-bootstrap"
import { myContext } from "../../provider"

import HomeCarousel from "../components/home/HomeCarousel"
const IndexPage = ({ data }) => {
  console.log(data)
  return (
    <myContext.Consumer>
      {context => (
        <Layout>
          <SEO title="Sorties et balades en bateau | Guide de pêche en mer" />
          <Container className="py-5 text-center">
            <Img
              fixed={data.logo.childImageSharp.fixed}
              alt="logo Cap Pêche Loisirs"
            />
            <h1 style={{ fontWeight: "200", textTransform: "uppercase" }}>
              Cap Pêche Loisirs
            </h1>
            <h2 style={{ fontWeight: "400", textTransform: "uppercase" }}>
              Skipper & guide de pêche en mer
            </h2>
            <h3>Capbreton – Landes | Saint-Jean-De-Luz — Pays Basque</h3>
          </Container>
          <HomeCarousel data={data} />
          <Container className="py-5">
            <Row className="py-3">
              <Col>
                <Link to="/prestations">
                  <Button>Voir les prestations et tarifs </Button>
                </Link>
              </Col>
            </Row>
            <p className="lead">
              <b>Christophe Barriola</b>, votre capitaine et guide de pêche en
              mer.
              <br />
              Vice champion du monde et six fois membre de l'équipe de France de
              pêche en bord de mer.
            </p>
            <p className="font-weight-light">
              Bienvenue sur notre site internet !
              <br />
              <Link to="/prestations">
                Découvrez nos différentes prestations{" "}
              </Link>
              de guidage de pêche en mer (bateau et bord de mer) ainsi que nos
              formules de balades et croisières en mer.
              <br />
              Vous pouvez également en profiter pour en savoir plus sur notre
              <Link to="/equipe"> équipe </Link>, nos
              <Link to="/materiel"> bateaux </Link>
              ainsi que le
              <Link to="/materiel"> matériel </Link>
              utilisé lors des sorties.
              <br />
            </p>
            <p>
              N’hésitez pas à nous contacter par
              <Link href="tel:+33675737251"> téléphone </Link>, par
              <Link href="mailto:contact@cappecheloisirs.com"> mail </Link>
              ou tout simplement via le
              <Link href="/contact"> formulaire de contact </Link>.
              <br />
              N’oubliez pas d’aimer notre page
              <Link
                href="https://facebook.com/cappecheloisirs"
                rel="noopener noreferrer"
              >
                Facebook
              </Link>
              et
              <Link
                href="https://instagram.com/cappecheloisirs"
                rel="noopener noreferrer"
              >
                Instagram
              </Link>
              pour ne rien manquer de notre actualité!
              <br />
              Bonne visite !
            </p>
          </Container>

          {context.isCookieEnabled && (
            <Container className="bg-white mb-4 py-3 d-none d-lg-block">
              <Row className="row mx-auto">
                <Col md={6} className=" d-flex justify-content-center">
                  <div className="TA_selfserveprop" id="TA_selfserveprop193">
                    <ul className="TA_links iZ4zbeVA" id="WG1vf4lAFuC">
                      <li className="qUzq8BE61m" id="SpA6zL10VON">
                        <a
                          href="https://www.tripadvisor.fr/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            alt="TripAdvisor"
                            src="https://www.tripadvisor.fr/img/cdsi/img2/branding/150_logo-11900-2.png"
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                  <script
                    async
                    data-loadtrk
                    onload="this.loadtrk=true"
                    src="https://www.jscache.com/wejs?wtype=selfserveprop&amp;uniq=193&amp;locationId=12644730&amp;lang=fr&amp;rating=true&amp;nreviews=5&amp;writereviewlink=true&amp;popIdx=true&amp;iswide=false&amp;border=true&amp;display_version=2"
                  ></script>
                </Col>
                <Col md={6}>
                  <iframe
                    allow="encrypted-media"
                    allowtransparency="true"
                    frameborder="0"
                    height="500"
                    scrolling="no"
                    src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fcappecheloisirs%2F&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                    style={{ border: "none", overflow: "hidden" }}
                    width="340"
                  ></iframe>
                </Col>
              </Row>
            </Container>
          )}
        </Layout>
      )}
    </myContext.Consumer>
  )
}

export const data = graphql`
  query {
    coucherDeSoleil: file(
      relativePath: { eq: "carousel/balade-au-coucher-du-soleil.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    peche: file(relativePath: { eq: "carousel/journee-peche-au-thon.jpg" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    balade: file(relativePath: { eq: "carousel/balade-en-mer.jpg" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    dophin1: file(relativePath: { eq: "carousel/dauphin1.jpg" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    dophin2: file(relativePath: { eq: "carousel/dauphin2.jpg" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bord: file(relativePath: { eq: "carousel/peche-en-bord-de-mer.jpg" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default IndexPage
